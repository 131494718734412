import React from "react"
import { Link } from "gatsby"

import { Navbar, Nav } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendar, faCameraRetro, faFileImage, faFilm, faIdCardAlt } from "@fortawesome/free-solid-svg-icons"
import { faFacebookSquare, faInstagramSquare } from "@fortawesome/free-brands-svg-icons"

const CustomNavbar = ({ siteTitle }) => {
  const pathName = typeof window !== 'undefined' ? window.location.pathname : '';
  return (
    <>
      <Navbar variant="dark" expand="lg" id="site-navbar">
        {/* <Container> */}
        <Link to="/" className="link-no-style">
          <Navbar.Brand as="span">
            <FontAwesomeIcon icon={faCameraRetro} className="mr-1" width="16" />
            {siteTitle}
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto" activeKey={pathName}>
            <a href="https://instagram.com/zannafede/" className="link-no-style" target="_blank" rel="noreferrer">
              <Nav.Link as="span" >
                <FontAwesomeIcon icon={faInstagramSquare} className="mr-1" width="16"  />
                Instagram
              </Nav.Link>
            </a>
            <a href="https://facebook.com/ZannaFede" className="link-no-style" target="_blank" rel="noreferrer">
              <Nav.Link as="span">
                <FontAwesomeIcon icon={faFacebookSquare} className="mr-1" width="16" />
                Facebook
              </Nav.Link>
            </a>
            <Link to="/curriculum-biografia" className="link-no-style">
              <Nav.Link as="span" eventKey="/curriculum-biografia">
                <FontAwesomeIcon icon={faIdCardAlt} className="mr-1" width="16" />
                Curriculum
              </Nav.Link>
            </Link>
            <Link to="/portfolio-fotografico" className="link-no-style">
              <Nav.Link as="span" eventKey="/portfolio-fotografico">
                <FontAwesomeIcon icon={faFileImage} className="mr-1" width="16" />
                Portfolio
              </Nav.Link>
            </Link>
            <Link to="/video-federico-zanetti" className="link-no-style">
              <Nav.Link as="span" eventKey="/video-federico-zanetti">
                <FontAwesomeIcon icon={faFilm} className="mr-1" width="16" />
                Video
              </Nav.Link>
            </Link>
            <Link to="/eventi" className="link-no-style">
              <Nav.Link as="span" eventKey="/eventi">
                <FontAwesomeIcon icon={faCalendar} className="mr-1" width="16" />
                Eventi
              </Nav.Link>
            </Link>
            {/* <Form inline onSubmit={e => e.preventDefault()}>
              <Form.Group>
                <FormControl
                  type="text"
                  placeholder="Fake Search"
                  className="mr-2"
                />
              </Form.Group>
              <Button>Fake Button</Button>
            </Form> */}
          </Nav>
        </Navbar.Collapse>
        {/* </Container> */}
      </Navbar>
    </>
  )
}

export default CustomNavbar
