/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Navbar from "./navBar"
import ContactForm from "./ContactForm"
import CookieConsent from "react-cookie-consent"
import Footer from "./Footer"

const Layout = ({ children, lang, meta, keywords, title  }) => {
  //console.log(lang, meta, keywords, title )
  return (
    <StaticQuery
      query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
      render={data => {
        return (
          <>
            <Navbar siteTitle={data.site.siteMetadata.title} />
            <main>
              {children}
            </main>
            <ContactForm />
            <Footer />
            <CookieConsent
              buttonText="Accetta"
              disableStyles={true}
              location="bottom"
              buttonClasses="btn btn-primary"
              containerClasses="fixed-bottom alert alert-dark col-lg-12 border border-primary"
              contentClasses=""
              cookieName="gatsby-gdpr-google-analytics">
              Utilizziamo cookie, anche di terza parte, per finalità tecniche, analitiche e di profilazione. Cliccando su "Accetta", acconsenti all’uso di tutti i cookie.
            </CookieConsent>
          </>
        )
      }}
    />
  )
}

export default Layout
