import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { faFacebookSquare, faInstagramSquare } from "@fortawesome/free-brands-svg-icons"
import { faCopyright } from "@fortawesome/free-regular-svg-icons"
import { Col, Container, Row } from "react-bootstrap"

const Footer = () => (
  <footer>
    <Container className="py-2 mt-2">
      <Row className="my-2 py-2">
        <Col className="footer-col">
          <ul className="list-unstyled">
            <li><span>
              <FontAwesomeIcon icon={faInstagramSquare} className="mr-1" />
              Instagram <a className="text-nowrap" href="https://www.instagram.com/zannafede/"> ZannaFede</a>
            </span></li>
            <li><span>
              <FontAwesomeIcon icon={faFacebookSquare} className="mr-1" />
              Pagina Facebook <a className="text-nowrap" href="https://facebook.com/ZannaFede"> Federico Zanna</a>
            </span>
            </li>
          </ul>
        </Col>
        <Col className="footer-col">
          <ul className="list-unstyled">
            <li><span>
              <FontAwesomeIcon icon={faCopyright} className="mr-1" />
              2021, sito web di <a className="text-nowrap" href="https://federicozanetti.it" >Federico Zanetti</a>
            </span></li>
          </ul>
        </Col>
      </Row>

    </Container>
  </footer>
)
export default Footer
