import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react"
import { Component } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";

class ContactForm extends Component {
    render() {
        return (
            <Container className="mt-3">
                <Row>
                    <Card className="card shadow-sm my-1" >
                        <Card.Body>
                            <Card.Title>
                                <h2 className="h1">Contattaci</h2>
                            </Card.Title>
                            <Card.Text className="lead">
                                Hai bisogno di un professionista o solo di qualche informazione extra?
                            </Card.Text>
                            <hr className="solid my-4 text-primary" />
                            <Form
                                name="contact"
                                method="POST"
                                data-netlify="true"
                                onSubmit="submit"
                                netlify-honeypot="bot-field"
                            >
                                <input type="hidden" name="form-name" value="contact" />
                                <p className="hidden d-none">
                                    <label>Don’t fill this out if you’re human: <input name="bot-field" /></label>
                                </p>
                                <Row>
                                    <Col md>
                                        <Form.Group className="mb-3" controlId="formContactEmail">
                                            <Form.Label>Indirizzo email</Form.Label>
                                            <Form.Control type="email" name="email" placeholder="La tua email" />
                                            <Form.Text className="text-muted">
                                                Non verrà mai condivisa con nessuno!
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                    <Col md>
                                        <Form.Group className="mb-3" controlId="formContactPassword">
                                            <Form.Label>Nome</Form.Label>
                                            <Form.Control type="text" name="name" placeholder="Il tuo nome" />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Form.Group className="mb-3" controlId="formContactMessage">
                                    <Form.Label>Messaggio</Form.Label>
                                    <Form.Control as="textarea" rows={4} name="message" />
                                </Form.Group>
                                <Row className="mb-3">
                                    <Col className="text-md-center text-lg-right">
                                        <Form.Group className="mb-3" controlId="formContactCheckbox">
                                            <Form.Check type="checkbox" name="newsletter" label="Iscriviti alla News Letter" />
                                        </Form.Group>
                                    </Col>
                                    <Col className="text-right">
                                        <Button variant="primary" type="submit">
                                            Invia
                                            <FontAwesomeIcon icon={faPaperPlane} />
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Row>
            </Container>
        )
    }
}
export default ContactForm